<template>
  <div class="studyPage">
    <Header />
    <Nav :route="navList" />
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import Header from "@/components/header";
import Nav from "@/components/nav";
export default {
  name: "study",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      navList: [
        {
          title: "我的基础练习",
          name: "MyBasicPractise",
        },
        {
          title: "我的练习任务",
          name: "MyPractiseTask",
        },
        {
          title: "我的考核任务",
          name: "MyAssessmentTask",
        },
        {
          title: "学习记录与统计",
          name: "study",
          link: true,
        },
      ],
      version: "low", //low=低版本,middle=中版本,high=高版本
    };
  },
  created() {
    this.getHospitalVersion();
  },
  methods: {
    async getHospitalVersion() {
      try {
        // let res = await this.$http.get("/rest-api/home/hospitalVersion");
        // this.version = res.data.data.version;
        this.version = JSON.parse(localStorage.getItem("Version"));
        if (this.version == "high") {
          this.navList = [
            {
              title: "我的基础练习",
              name: "MyBasicPractise",
            },
            {
              title: "我的练习任务",
              name: "MyPractiseTask",
            },
            {
              title: "我的考核任务",
              name: "MyAssessmentTask",
            },
            {
              title: "学习记录与统计",
              name: "study",
              link: true,
            },
          ];
        } else if (this.version == "middle") {
          this.navList = [
            {
              title: "我的基础练习",
              name: "MyBasicPractise",
            },
            {
              title: "我的练习任务",
              name: "MyPractiseTask",
            },

            {
              title: "学习记录与统计",
              name: "study",
              link: true,
            },
          ];
        } else {
          this.navList = [
            {
              title: "我的基础练习",
              name: "MyBasicPractise",
            },
            {
              title: "学习记录与统计",
              name: "study",
              link: true,
            },
          ];
        }
        console.log(this.navList, "  this.navList");
      } catch (err) {}
    },
  },
};
</script>
<style lang="scss">
@import "index.scss";
</style>